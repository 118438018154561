import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name cpu
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB4PSI0IiB5PSI0IiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHJ4PSIyIiAvPgogIDxyZWN0IHg9IjkiIHk9IjkiIHdpZHRoPSI2IiBoZWlnaHQ9IjYiIC8+CiAgPHBhdGggZD0iTTE1IDJ2MiIgLz4KICA8cGF0aCBkPSJNMTUgMjB2MiIgLz4KICA8cGF0aCBkPSJNMiAxNWgyIiAvPgogIDxwYXRoIGQ9Ik0yIDloMiIgLz4KICA8cGF0aCBkPSJNMjAgMTVoMiIgLz4KICA8cGF0aCBkPSJNMjAgOWgyIiAvPgogIDxwYXRoIGQ9Ik05IDJ2MiIgLz4KICA8cGF0aCBkPSJNOSAyMHYyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/cpu
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Cpu: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { x: '4', y: '4', width: '16', height: '16', rx: '2' }],
    ['rect', { x: '9', y: '9', width: '6', height: '6' }],
    ['path', { d: 'M15 2v2' }],
    ['path', { d: 'M15 20v2' }],
    ['path', { d: 'M2 15h2' }],
    ['path', { d: 'M2 9h2' }],
    ['path', { d: 'M20 15h2' }],
    ['path', { d: 'M20 9h2' }],
    ['path', { d: 'M9 2v2' }],
    ['path', { d: 'M9 20v2' }],
  ],
];

export default Cpu;
