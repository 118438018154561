import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name cable
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCA5YTIgMiAwIDAgMS0yLTJWNWg2djJhMiAyIDAgMCAxLTIgMloiIC8+CiAgPHBhdGggZD0iTTMgNVYzIiAvPgogIDxwYXRoIGQ9Ik03IDVWMyIgLz4KICA8cGF0aCBkPSJNMTkgMTVWNi41YTMuNSAzLjUgMCAwIDAtNyAwdjExYTMuNSAzLjUgMCAwIDEtNyAwVjkiIC8+CiAgPHBhdGggZD0iTTE3IDIxdi0yIiAvPgogIDxwYXRoIGQ9Ik0yMSAyMXYtMiIgLz4KICA8cGF0aCBkPSJNMjIgMTloLTZ2LTJhMiAyIDAgMCAxIDItMmgyYTIgMiAwIDAgMSAyIDJaIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/cable
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Cable: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M4 9a2 2 0 0 1-2-2V5h6v2a2 2 0 0 1-2 2Z' }],
    ['path', { d: 'M3 5V3' }],
    ['path', { d: 'M7 5V3' }],
    ['path', { d: 'M19 15V6.5a3.5 3.5 0 0 0-7 0v11a3.5 3.5 0 0 1-7 0V9' }],
    ['path', { d: 'M17 21v-2' }],
    ['path', { d: 'M21 21v-2' }],
    ['path', { d: 'M22 19h-6v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2Z' }],
  ],
];

export default Cable;
