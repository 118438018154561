import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name anchor
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjUiIHI9IjMiIC8+CiAgPGxpbmUgeDE9IjEyIiB4Mj0iMTIiIHkxPSIyMiIgeTI9IjgiIC8+CiAgPHBhdGggZD0iTTUgMTJIMmExMCAxMCAwIDAgMCAyMCAwaC0zIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/anchor
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Anchor: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '12', cy: '5', r: '3' }],
    ['line', { x1: '12', x2: '12', y1: '22', y2: '8' }],
    ['path', { d: 'M5 12H2a10 10 0 0 0 20 0h-3' }],
  ],
];

export default Anchor;
