import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name contact-2
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTYgMThhNCA0IDAgMCAwLTggMCIgLz4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjExIiByPSIzIiAvPgogIDxyZWN0IHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgeD0iMyIgeT0iNCIgcng9IjIiIC8+CiAgPGxpbmUgeDE9IjgiIHgyPSI4IiB5MT0iMiIgeTI9IjQiIC8+CiAgPGxpbmUgeDE9IjE2IiB4Mj0iMTYiIHkxPSIyIiB5Mj0iNCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/contact-2
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Contact2: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M16 18a4 4 0 0 0-8 0' }],
    ['circle', { cx: '12', cy: '11', r: '3' }],
    ['rect', { width: '18', height: '18', x: '3', y: '4', rx: '2' }],
    ['line', { x1: '8', x2: '8', y1: '2', y2: '4' }],
    ['line', { x1: '16', x2: '16', y1: '2', y2: '4' }],
  ],
];

export default Contact2;
