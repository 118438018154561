import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name wand-2
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMjEuNjQgMy42NC0xLjI4LTEuMjhhMS4yMSAxLjIxIDAgMCAwLTEuNzIgMEwyLjM2IDE4LjY0YTEuMjEgMS4yMSAwIDAgMCAwIDEuNzJsMS4yOCAxLjI4YTEuMiAxLjIgMCAwIDAgMS43MiAwTDIxLjY0IDUuMzZhMS4yIDEuMiAwIDAgMCAwLTEuNzJaIiAvPgogIDxwYXRoIGQ9Im0xNCA3IDMgMyIgLz4KICA8cGF0aCBkPSJNNSA2djQiIC8+CiAgPHBhdGggZD0iTTE5IDE0djQiIC8+CiAgPHBhdGggZD0iTTEwIDJ2MiIgLz4KICA8cGF0aCBkPSJNNyA4SDMiIC8+CiAgPHBhdGggZD0iTTIxIDE2aC00IiAvPgogIDxwYXRoIGQ9Ik0xMSAzSDkiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/wand-2
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Wand2: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'm21.64 3.64-1.28-1.28a1.21 1.21 0 0 0-1.72 0L2.36 18.64a1.21 1.21 0 0 0 0 1.72l1.28 1.28a1.2 1.2 0 0 0 1.72 0L21.64 5.36a1.2 1.2 0 0 0 0-1.72Z',
      },
    ],
    ['path', { d: 'm14 7 3 3' }],
    ['path', { d: 'M5 6v4' }],
    ['path', { d: 'M19 14v4' }],
    ['path', { d: 'M10 2v2' }],
    ['path', { d: 'M7 8H3' }],
    ['path', { d: 'M21 16h-4' }],
    ['path', { d: 'M11 3H9' }],
  ],
];

export default Wand2;
