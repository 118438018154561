import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name drumstick
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTUuNDUgMTUuNGMtMi4xMy42NS00LjMuMzItNS43LTEuMS0yLjI5LTIuMjctMS43Ni02LjUgMS4xNy05LjQyIDIuOTMtMi45MyA3LjE1LTMuNDYgOS40My0xLjE4IDEuNDEgMS40MSAxLjc0IDMuNTcgMS4xIDUuNzEtMS40LS41MS0zLjI2LS4wMi00LjY0IDEuMzYtMS4zOCAxLjM4LTEuODcgMy4yMy0xLjM2IDQuNjN6IiAvPgogIDxwYXRoIGQ9Im0xMS4yNSAxNS42LTIuMTYgMi4xNmEyLjUgMi41IDAgMSAxLTQuNTYgMS43MyAyLjQ5IDIuNDkgMCAwIDEtMS40MS00LjI0IDIuNSAyLjUgMCAwIDEgMy4xNC0uMzJsMi4xNi0yLjE2IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/drumstick
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Drumstick: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M15.45 15.4c-2.13.65-4.3.32-5.7-1.1-2.29-2.27-1.76-6.5 1.17-9.42 2.93-2.93 7.15-3.46 9.43-1.18 1.41 1.41 1.74 3.57 1.1 5.71-1.4-.51-3.26-.02-4.64 1.36-1.38 1.38-1.87 3.23-1.36 4.63z',
      },
    ],
    [
      'path',
      {
        d: 'm11.25 15.6-2.16 2.16a2.5 2.5 0 1 1-4.56 1.73 2.49 2.49 0 0 1-1.41-4.24 2.5 2.5 0 0 1 3.14-.32l2.16-2.16',
      },
    ],
  ],
];

export default Drumstick;
