import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name wallet
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjEgMTJWN0g1YTIgMiAwIDAgMSAwLTRoMTR2NCIgLz4KICA8cGF0aCBkPSJNMyA1djE0YTIgMiAwIDAgMCAyIDJoMTZ2LTUiIC8+CiAgPHBhdGggZD0iTTE4IDEyYTIgMiAwIDAgMCAwIDRoNHYtNFoiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/wallet
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Wallet: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M21 12V7H5a2 2 0 0 1 0-4h14v4' }],
    ['path', { d: 'M3 5v14a2 2 0 0 0 2 2h16v-5' }],
    ['path', { d: 'M18 12a2 2 0 0 0 0 4h4v-4Z' }],
  ],
];

export default Wallet;
