import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name concierge-bell
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMiAxOGEyIDIgMCAwIDEgMi0yaDE2YTIgMiAwIDAgMSAyIDJ2Mkgydi0yWiIgLz4KICA8cGF0aCBkPSJNMjAgMTZhOCA4IDAgMSAwLTE2IDAiIC8+CiAgPHBhdGggZD0iTTEyIDR2NCIgLz4KICA8cGF0aCBkPSJNMTAgNGg0IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/concierge-bell
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ConciergeBell: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M2 18a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v2H2v-2Z' }],
    ['path', { d: 'M20 16a8 8 0 1 0-16 0' }],
    ['path', { d: 'M12 4v4' }],
    ['path', { d: 'M10 4h4' }],
  ],
];

export default ConciergeBell;
