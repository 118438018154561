import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name hotel
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTggMkg2YTIgMiAwIDAgMC0yIDJ2MTZhMiAyIDAgMCAwIDIgMmgxMmEyIDIgMCAwIDAgMi0yVjRhMiAyIDAgMCAwLTItMloiIC8+CiAgPHBhdGggZD0ibTkgMTYgLjM0OC0uMjRjMS40NjUtMS4wMTMgMy44NC0xLjAxMyA1LjMwNCAwTDE1IDE2IiAvPgogIDxwYXRoIGQ9Ik04IDdoLjAxIiAvPgogIDxwYXRoIGQ9Ik0xNiA3aC4wMSIgLz4KICA8cGF0aCBkPSJNMTIgN2guMDEiIC8+CiAgPHBhdGggZD0iTTEyIDExaC4wMSIgLz4KICA8cGF0aCBkPSJNMTYgMTFoLjAxIiAvPgogIDxwYXRoIGQ9Ik04IDExaC4wMSIgLz4KICA8cGF0aCBkPSJNMTAgMjJ2LTYuNW00IDBWMjIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/hotel
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Hotel: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M18 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2Z' }],
    ['path', { d: 'm9 16 .348-.24c1.465-1.013 3.84-1.013 5.304 0L15 16' }],
    ['path', { d: 'M8 7h.01' }],
    ['path', { d: 'M16 7h.01' }],
    ['path', { d: 'M12 7h.01' }],
    ['path', { d: 'M12 11h.01' }],
    ['path', { d: 'M16 11h.01' }],
    ['path', { d: 'M8 11h.01' }],
    ['path', { d: 'M10 22v-6.5m4 0V22' }],
  ],
];

export default Hotel;
