import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name siren
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNyAxMmE1IDUgMCAwIDEgNS01djBhNSA1IDAgMCAxIDUgNXY2SDd2LTZaIiAvPgogIDxwYXRoIGQ9Ik01IDIwYTIgMiAwIDAgMSAyLTJoMTBhMiAyIDAgMCAxIDIgMnYySDV2LTJaIiAvPgogIDxwYXRoIGQ9Ik0yMSAxMmgxIiAvPgogIDxwYXRoIGQ9Ik0xOC41IDQuNSAxOCA1IiAvPgogIDxwYXRoIGQ9Ik0yIDEyaDEiIC8+CiAgPHBhdGggZD0iTTEyIDJ2MSIgLz4KICA8cGF0aCBkPSJtNC45MjkgNC45MjkuNzA3LjcwNyIgLz4KICA8cGF0aCBkPSJNMTIgMTJ2NiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/siren
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Siren: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M7 12a5 5 0 0 1 5-5v0a5 5 0 0 1 5 5v6H7v-6Z' }],
    ['path', { d: 'M5 20a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v2H5v-2Z' }],
    ['path', { d: 'M21 12h1' }],
    ['path', { d: 'M18.5 4.5 18 5' }],
    ['path', { d: 'M2 12h1' }],
    ['path', { d: 'M12 2v1' }],
    ['path', { d: 'm4.929 4.929.707.707' }],
    ['path', { d: 'M12 12v6' }],
  ],
];

export default Siren;
