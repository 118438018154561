import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name brain-cog
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSIzIiAvPgogIDxwYXRoIGQ9Ik0xMiA0LjVhMi41IDIuNSAwIDAgMC00Ljk2LS40NiAyLjUgMi41IDAgMCAwLTEuOTggMyAyLjUgMi41IDAgMCAwLTEuMzIgNC4yNCAzIDMgMCAwIDAgLjM0IDUuNTggMi41IDIuNSAwIDAgMCAyLjk2IDMuMDhBMi41IDIuNSAwIDAgMCAxMiAxOS41YTIuNSAyLjUgMCAwIDAgNC45Ni40NCAyLjUgMi41IDAgMCAwIDIuOTYtMy4wOCAzIDMgMCAwIDAgLjM0LTUuNTggMi41IDIuNSAwIDAgMC0xLjMyLTQuMjQgMi41IDIuNSAwIDAgMC0xLjk4LTNBMi41IDIuNSAwIDAgMCAxMiA0LjUiIC8+CiAgPHBhdGggZD0ibTE1LjcgMTAuNC0uOS40IiAvPgogIDxwYXRoIGQ9Im05LjIgMTMuMi0uOS40IiAvPgogIDxwYXRoIGQ9Im0xMy42IDE1LjctLjQtLjkiIC8+CiAgPHBhdGggZD0ibTEwLjggOS4yLS40LS45IiAvPgogIDxwYXRoIGQ9Im0xNS43IDEzLjUtLjktLjQiIC8+CiAgPHBhdGggZD0ibTkuMiAxMC45LS45LS40IiAvPgogIDxwYXRoIGQ9Im0xMC41IDE1LjcuNC0uOSIgLz4KICA8cGF0aCBkPSJtMTMuMSA5LjIuNC0uOSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/brain-cog
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BrainCog: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '12', cy: '12', r: '3' }],
    [
      'path',
      {
        d: 'M12 4.5a2.5 2.5 0 0 0-4.96-.46 2.5 2.5 0 0 0-1.98 3 2.5 2.5 0 0 0-1.32 4.24 3 3 0 0 0 .34 5.58 2.5 2.5 0 0 0 2.96 3.08A2.5 2.5 0 0 0 12 19.5a2.5 2.5 0 0 0 4.96.44 2.5 2.5 0 0 0 2.96-3.08 3 3 0 0 0 .34-5.58 2.5 2.5 0 0 0-1.32-4.24 2.5 2.5 0 0 0-1.98-3A2.5 2.5 0 0 0 12 4.5',
      },
    ],
    ['path', { d: 'm15.7 10.4-.9.4' }],
    ['path', { d: 'm9.2 13.2-.9.4' }],
    ['path', { d: 'm13.6 15.7-.4-.9' }],
    ['path', { d: 'm10.8 9.2-.4-.9' }],
    ['path', { d: 'm15.7 13.5-.9-.4' }],
    ['path', { d: 'm9.2 10.9-.9-.4' }],
    ['path', { d: 'm10.5 15.7.4-.9' }],
    ['path', { d: 'm13.1 9.2.4-.9' }],
  ],
];

export default BrainCog;
