import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name anvil
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNyAxMGMtMi44IDAtNS0yLjItNS01aDUiIC8+CiAgPHBhdGggZD0iTTcgNHY4aDdhOCA4IDAgMCAwIDgtOFoiIC8+CiAgPHBhdGggZD0iTTkgMTJ2NSIgLz4KICA8cGF0aCBkPSJNMTUgMTJ2NSIgLz4KICA8cGF0aCBkPSJNNSAyMGEzIDMgMCAwIDEgMy0zaDhhMyAzIDAgMCAxIDMgM3YxSDVaIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/anvil
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Anvil: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M7 10c-2.8 0-5-2.2-5-5h5' }],
    ['path', { d: 'M7 4v8h7a8 8 0 0 0 8-8Z' }],
    ['path', { d: 'M9 12v5' }],
    ['path', { d: 'M15 12v5' }],
    ['path', { d: 'M5 20a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v1H5Z' }],
  ],
];

export default Anvil;
