import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name zap-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cG9seWxpbmUgcG9pbnRzPSIxMi40MSA2Ljc1IDEzIDIgMTAuNTcgNC45MiIgLz4KICA8cG9seWxpbmUgcG9pbnRzPSIxOC41NyAxMi45MSAyMSAxMCAxNS42NiAxMCIgLz4KICA8cG9seWxpbmUgcG9pbnRzPSI4IDggMyAxNCAxMiAxNCAxMSAyMiAxNiAxNiIgLz4KICA8bGluZSB4MT0iMiIgeDI9IjIyIiB5MT0iMiIgeTI9IjIyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/zap-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ZapOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '12.41 6.75 13 2 10.57 4.92' }],
    ['polyline', { points: '18.57 12.91 21 10 15.66 10' }],
    ['polyline', { points: '8 8 3 14 12 14 11 22 16 16' }],
    ['line', { x1: '2', x2: '22', y1: '2', y2: '22' }],
  ],
];

export default ZapOff;
