import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name package-open
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjAuOTEgOC44NCA4LjU2IDIuMjNhMS45MyAxLjkzIDAgMCAwLTEuODEgMEwzLjEgNC4xM2EyLjEyIDIuMTIgMCAwIDAtLjA1IDMuNjlsMTIuMjIgNi45M2EyIDIgMCAwIDAgMS45NCAwTDIxIDEyLjUxYTIuMTIgMi4xMiAwIDAgMC0uMDktMy42N1oiIC8+CiAgPHBhdGggZD0ibTMuMDkgOC44NCAxMi4zNS02LjYxYTEuOTMgMS45MyAwIDAgMSAxLjgxIDBsMy42NSAxLjlhMi4xMiAyLjEyIDAgMCAxIC4xIDMuNjlMOC43MyAxNC43NWEyIDIgMCAwIDEtMS45NCAwTDMgMTIuNTFhMi4xMiAyLjEyIDAgMCAxIC4wOS0zLjY3WiIgLz4KICA8bGluZSB4MT0iMTIiIHgyPSIxMiIgeTE9IjIyIiB5Mj0iMTMiIC8+CiAgPHBhdGggZD0iTTIwIDEzLjV2My4zN2EyLjA2IDIuMDYgMCAwIDEtMS4xMSAxLjgzbC02IDMuMDhhMS45MyAxLjkzIDAgMCAxLTEuNzggMGwtNi0zLjA4QTIuMDYgMi4wNiAwIDAgMSA0IDE2Ljg3VjEzLjUiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/package-open
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const PackageOpen: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M20.91 8.84 8.56 2.23a1.93 1.93 0 0 0-1.81 0L3.1 4.13a2.12 2.12 0 0 0-.05 3.69l12.22 6.93a2 2 0 0 0 1.94 0L21 12.51a2.12 2.12 0 0 0-.09-3.67Z',
      },
    ],
    [
      'path',
      {
        d: 'm3.09 8.84 12.35-6.61a1.93 1.93 0 0 1 1.81 0l3.65 1.9a2.12 2.12 0 0 1 .1 3.69L8.73 14.75a2 2 0 0 1-1.94 0L3 12.51a2.12 2.12 0 0 1 .09-3.67Z',
      },
    ],
    ['line', { x1: '12', x2: '12', y1: '22', y2: '13' }],
    [
      'path',
      {
        d: 'M20 13.5v3.37a2.06 2.06 0 0 1-1.11 1.83l-6 3.08a1.93 1.93 0 0 1-1.78 0l-6-3.08A2.06 2.06 0 0 1 4 16.87V13.5',
      },
    ],
  ],
];

export default PackageOpen;
