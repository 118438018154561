import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name sliders
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8bGluZSB4MT0iNCIgeDI9IjQiIHkxPSIyMSIgeTI9IjE0IiAvPgogIDxsaW5lIHgxPSI0IiB4Mj0iNCIgeTE9IjEwIiB5Mj0iMyIgLz4KICA8bGluZSB4MT0iMTIiIHgyPSIxMiIgeTE9IjIxIiB5Mj0iMTIiIC8+CiAgPGxpbmUgeDE9IjEyIiB4Mj0iMTIiIHkxPSI4IiB5Mj0iMyIgLz4KICA8bGluZSB4MT0iMjAiIHgyPSIyMCIgeTE9IjIxIiB5Mj0iMTYiIC8+CiAgPGxpbmUgeDE9IjIwIiB4Mj0iMjAiIHkxPSIxMiIgeTI9IjMiIC8+CiAgPGxpbmUgeDE9IjIiIHgyPSI2IiB5MT0iMTQiIHkyPSIxNCIgLz4KICA8bGluZSB4MT0iMTAiIHgyPSIxNCIgeTE9IjgiIHkyPSI4IiAvPgogIDxsaW5lIHgxPSIxOCIgeDI9IjIyIiB5MT0iMTYiIHkyPSIxNiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/sliders
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Sliders: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '4', x2: '4', y1: '21', y2: '14' }],
    ['line', { x1: '4', x2: '4', y1: '10', y2: '3' }],
    ['line', { x1: '12', x2: '12', y1: '21', y2: '12' }],
    ['line', { x1: '12', x2: '12', y1: '8', y2: '3' }],
    ['line', { x1: '20', x2: '20', y1: '21', y2: '16' }],
    ['line', { x1: '20', x2: '20', y1: '12', y2: '3' }],
    ['line', { x1: '2', x2: '6', y1: '14', y2: '14' }],
    ['line', { x1: '10', x2: '14', y1: '8', y2: '8' }],
    ['line', { x1: '18', x2: '22', y1: '16', y2: '16' }],
  ],
];

export default Sliders;
