import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name clover
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTYuMiAzLjhhMi43IDIuNyAwIDAgMC0zLjgxIDBsLS40LjM4LS40LS40YTIuNyAyLjcgMCAwIDAtMy44MiAwQzYuNzMgNC44NSA2LjY3IDYuNjQgOCA4bDQgNCA0LTRjMS4zMy0xLjM2IDEuMjctMy4xNS4yLTQuMnoiIC8+CiAgPHBhdGggZD0iTTggOGMtMS4zNi0xLjMzLTMuMTUtMS4yNy00LjItLjJhMi43IDIuNyAwIDAgMCAwIDMuODFsLjM4LjQtLjQuNGEyLjcgMi43IDAgMCAwIDAgMy44MkM0Ljg1IDE3LjI3IDYuNjQgMTcuMzMgOCAxNiIgLz4KICA8cGF0aCBkPSJNMTYgMTZjMS4zNiAxLjMzIDMuMTUgMS4yNyA0LjIuMmEyLjcgMi43IDAgMCAwIDAtMy44MWwtLjM4LS40LjQtLjRhMi43IDIuNyAwIDAgMCAwLTMuODJDMTkuMTUgNi43MyAxNy4zNiA2LjY3IDE2IDgiIC8+CiAgPHBhdGggZD0iTTcuOCAyMC4yYTIuNyAyLjcgMCAwIDAgMy44MSAwbC40LS4zOC40LjRhMi43IDIuNyAwIDAgMCAzLjgyIDBjMS4wNi0xLjA2IDEuMTItMi44NS0uMjEtNC4yMWwtNC00LTQgNGMtMS4zMyAxLjM2LTEuMjcgMy4xNS0uMiA0LjJ6IiAvPgogIDxwYXRoIGQ9Im03IDE3LTUgNSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/clover
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Clover: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M16.2 3.8a2.7 2.7 0 0 0-3.81 0l-.4.38-.4-.4a2.7 2.7 0 0 0-3.82 0C6.73 4.85 6.67 6.64 8 8l4 4 4-4c1.33-1.36 1.27-3.15.2-4.2z',
      },
    ],
    [
      'path',
      {
        d: 'M8 8c-1.36-1.33-3.15-1.27-4.2-.2a2.7 2.7 0 0 0 0 3.81l.38.4-.4.4a2.7 2.7 0 0 0 0 3.82C4.85 17.27 6.64 17.33 8 16',
      },
    ],
    [
      'path',
      {
        d: 'M16 16c1.36 1.33 3.15 1.27 4.2.2a2.7 2.7 0 0 0 0-3.81l-.38-.4.4-.4a2.7 2.7 0 0 0 0-3.82C19.15 6.73 17.36 6.67 16 8',
      },
    ],
    [
      'path',
      {
        d: 'M7.8 20.2a2.7 2.7 0 0 0 3.81 0l.4-.38.4.4a2.7 2.7 0 0 0 3.82 0c1.06-1.06 1.12-2.85-.21-4.21l-4-4-4 4c-1.33 1.36-1.27 3.15-.2 4.2z',
      },
    ],
    ['path', { d: 'm7 17-5 5' }],
  ],
];

export default Clover;
