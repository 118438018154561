import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name arrow-big-down-dash
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTUgNUg5IiAvPgogIDxwYXRoIGQ9Ik0xNSA5djNoNGwtNyA3LTctN2g0VjloNnoiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/arrow-big-down-dash
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ArrowBigDownDash: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M15 5H9' }],
    ['path', { d: 'M15 9v3h4l-7 7-7-7h4V9h6z' }],
  ],
];

export default ArrowBigDownDash;
