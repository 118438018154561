import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name file-pie-chart
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTYgMjJoMmEyIDIgMCAwIDAgMi0yVjdsLTUtNUg2YTIgMiAwIDAgMC0yIDJ2MyIgLz4KICA8cGF0aCBkPSJNMTQgMnY0YTIgMiAwIDAgMCAyIDJoNCIgLz4KICA8cGF0aCBkPSJNNCAxMS41YTYuMDIgNi4wMiAwIDEgMCA4LjUgOC41IiAvPgogIDxwYXRoIGQ9Ik0xNCAxNmMwLTMuMy0yLjctNi02LTZ2NloiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/file-pie-chart
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const FilePieChart: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M16 22h2a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v3' }],
    ['path', { d: 'M14 2v4a2 2 0 0 0 2 2h4' }],
    ['path', { d: 'M4 11.5a6.02 6.02 0 1 0 8.5 8.5' }],
    ['path', { d: 'M14 16c0-3.3-2.7-6-6-6v6Z' }],
  ],
];

export default FilePieChart;
