import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name power-circle
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSIxMCIgLz4KICA8cGF0aCBkPSJNMTIgMTJWNiIgLz4KICA8cGF0aCBkPSJNOCA3LjVBNi4xIDYuMSAwIDAgMCAxMiAxOGE2IDYgMCAwIDAgNC0xMC41IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/power-circle
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const PowerCircle: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '12', cy: '12', r: '10' }],
    ['path', { d: 'M12 12V6' }],
    ['path', { d: 'M8 7.5A6.1 6.1 0 0 0 12 18a6 6 0 0 0 4-10.5' }],
  ],
];

export default PowerCircle;
