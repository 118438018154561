import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name hammer
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTUgMTItOC41IDguNWMtLjgzLjgzLTIuMTcuODMtMyAwIDAgMCAwIDAgMCAwYTIuMTIgMi4xMiAwIDAgMSAwLTNMMTIgOSIgLz4KICA8cGF0aCBkPSJNMTcuNjQgMTUgMjIgMTAuNjQiIC8+CiAgPHBhdGggZD0ibTIwLjkxIDExLjctMS4yNS0xLjI1Yy0uNi0uNi0uOTMtMS40LS45My0yLjI1di0uODZMMTYuMDEgNC42YTUuNTYgNS41NiAwIDAgMC0zLjk0LTEuNjRIOWwuOTIuODJBNi4xOCA2LjE4IDAgMCAxIDEyIDguNHYxLjU2bDIgMmgyLjQ3bDIuMjYgMS45MSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/hammer
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Hammer: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm15 12-8.5 8.5c-.83.83-2.17.83-3 0 0 0 0 0 0 0a2.12 2.12 0 0 1 0-3L12 9' }],
    ['path', { d: 'M17.64 15 22 10.64' }],
    [
      'path',
      {
        d: 'm20.91 11.7-1.25-1.25c-.6-.6-.93-1.4-.93-2.25v-.86L16.01 4.6a5.56 5.56 0 0 0-3.94-1.64H9l.92.82A6.18 6.18 0 0 1 12 8.4v1.56l2 2h2.47l2.26 1.91',
      },
    ],
  ],
];

export default Hammer;
