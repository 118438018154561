import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name wifi-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8bGluZSB4MT0iMiIgeDI9IjIyIiB5MT0iMiIgeTI9IjIyIiAvPgogIDxwYXRoIGQ9Ik04LjUgMTYuNWE1IDUgMCAwIDEgNyAwIiAvPgogIDxwYXRoIGQ9Ik0yIDguODJhMTUgMTUgMCAwIDEgNC4xNy0yLjY1IiAvPgogIDxwYXRoIGQ9Ik0xMC42NiA1YzQuMDEtLjM2IDguMTQuOSAxMS4zNCAzLjc2IiAvPgogIDxwYXRoIGQ9Ik0xNi44NSAxMS4yNWExMCAxMCAwIDAgMSAyLjIyIDEuNjgiIC8+CiAgPHBhdGggZD0iTTUgMTNhMTAgMTAgMCAwIDEgNS4yNC0yLjc2IiAvPgogIDxsaW5lIHgxPSIxMiIgeDI9IjEyLjAxIiB5MT0iMjAiIHkyPSIyMCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/wifi-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const WifiOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '2', x2: '22', y1: '2', y2: '22' }],
    ['path', { d: 'M8.5 16.5a5 5 0 0 1 7 0' }],
    ['path', { d: 'M2 8.82a15 15 0 0 1 4.17-2.65' }],
    ['path', { d: 'M10.66 5c4.01-.36 8.14.9 11.34 3.76' }],
    ['path', { d: 'M16.85 11.25a10 10 0 0 1 2.22 1.68' }],
    ['path', { d: 'M5 13a10 10 0 0 1 5.24-2.76' }],
    ['line', { x1: '12', x2: '12.01', y1: '20', y2: '20' }],
  ],
];

export default WifiOff;
