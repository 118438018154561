import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name tram-front
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHg9IjQiIHk9IjMiIHJ4PSIyIiAvPgogIDxwYXRoIGQ9Ik00IDExaDE2IiAvPgogIDxwYXRoIGQ9Ik0xMiAzdjgiIC8+CiAgPHBhdGggZD0ibTggMTktMiAzIiAvPgogIDxwYXRoIGQ9Im0xOCAyMi0yLTMiIC8+CiAgPHBhdGggZD0iTTggMTVoMCIgLz4KICA8cGF0aCBkPSJNMTYgMTVoMCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/tram-front
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const TramFront: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { width: '16', height: '16', x: '4', y: '3', rx: '2' }],
    ['path', { d: 'M4 11h16' }],
    ['path', { d: 'M12 3v8' }],
    ['path', { d: 'm8 19-2 3' }],
    ['path', { d: 'm18 22-2-3' }],
    ['path', { d: 'M8 15h0' }],
    ['path', { d: 'M16 15h0' }],
  ],
];

export default TramFront;
