import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name tractor
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMyA0aDlsMSA3IiAvPgogIDxwYXRoIGQ9Ik00IDExVjQiIC8+CiAgPHBhdGggZD0iTTggMTBWNCIgLz4KICA8cGF0aCBkPSJNMTggNWMtLjYgMC0xIC40LTEgMXY1LjYiIC8+CiAgPHBhdGggZD0ibTEwIDExIDExIC45Yy42IDAgLjkuNS44IDEuMWwtLjggNWgtMSIgLz4KICA8Y2lyY2xlIGN4PSI3IiBjeT0iMTUiIHI9Ii41IiAvPgogIDxjaXJjbGUgY3g9IjciIGN5PSIxNSIgcj0iNSIgLz4KICA8cGF0aCBkPSJNMTYgMThoLTUiIC8+CiAgPGNpcmNsZSBjeD0iMTgiIGN5PSIxOCIgcj0iMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/tractor
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Tractor: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M3 4h9l1 7' }],
    ['path', { d: 'M4 11V4' }],
    ['path', { d: 'M8 10V4' }],
    ['path', { d: 'M18 5c-.6 0-1 .4-1 1v5.6' }],
    ['path', { d: 'm10 11 11 .9c.6 0 .9.5.8 1.1l-.8 5h-1' }],
    ['circle', { cx: '7', cy: '15', r: '.5' }],
    ['circle', { cx: '7', cy: '15', r: '5' }],
    ['path', { d: 'M16 18h-5' }],
    ['circle', { cx: '18', cy: '18', r: '2' }],
  ],
];

export default Tractor;
