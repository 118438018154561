import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name brain-circuit
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgNC41YTIuNSAyLjUgMCAwIDAtNC45Ni0uNDYgMi41IDIuNSAwIDAgMC0xLjk4IDMgMi41IDIuNSAwIDAgMC0xLjMyIDQuMjQgMyAzIDAgMCAwIC4zNCA1LjU4IDIuNSAyLjUgMCAwIDAgMi45NiAzLjA4IDIuNSAyLjUgMCAwIDAgNC45MS4wNUwxMiAyMFY0LjVaIiAvPgogIDxwYXRoIGQ9Ik0xNiA4VjVjMC0xLjEuOS0yIDItMiIgLz4KICA8cGF0aCBkPSJNMTIgMTNoNCIgLz4KICA8cGF0aCBkPSJNMTIgMThoNmEyIDIgMCAwIDEgMiAydjEiIC8+CiAgPHBhdGggZD0iTTEyIDhoOCIgLz4KICA8cGF0aCBkPSJNMjAuNSA4YS41LjUgMCAxIDEtMSAwIC41LjUgMCAwIDEgMSAwWiIgLz4KICA8cGF0aCBkPSJNMTYuNSAxM2EuNS41IDAgMSAxLTEgMCAuNS41IDAgMCAxIDEgMFoiIC8+CiAgPHBhdGggZD0iTTIwLjUgMjFhLjUuNSAwIDEgMS0xIDAgLjUuNSAwIDAgMSAxIDBaIiAvPgogIDxwYXRoIGQ9Ik0xOC41IDNhLjUuNSAwIDEgMS0xIDAgLjUuNSAwIDAgMSAxIDBaIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/brain-circuit
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BrainCircuit: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M12 4.5a2.5 2.5 0 0 0-4.96-.46 2.5 2.5 0 0 0-1.98 3 2.5 2.5 0 0 0-1.32 4.24 3 3 0 0 0 .34 5.58 2.5 2.5 0 0 0 2.96 3.08 2.5 2.5 0 0 0 4.91.05L12 20V4.5Z',
      },
    ],
    ['path', { d: 'M16 8V5c0-1.1.9-2 2-2' }],
    ['path', { d: 'M12 13h4' }],
    ['path', { d: 'M12 18h6a2 2 0 0 1 2 2v1' }],
    ['path', { d: 'M12 8h8' }],
    ['path', { d: 'M20.5 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z' }],
    ['path', { d: 'M16.5 13a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z' }],
    ['path', { d: 'M20.5 21a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z' }],
    ['path', { d: 'M18.5 3a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z' }],
  ],
];

export default BrainCircuit;
