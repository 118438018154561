import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name coffee
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTcgOGgxYTQgNCAwIDEgMSAwIDhoLTEiIC8+CiAgPHBhdGggZD0iTTMgOGgxNHY5YTQgNCAwIDAgMS00IDRIN2E0IDQgMCAwIDEtNC00WiIgLz4KICA8bGluZSB4MT0iNiIgeDI9IjYiIHkxPSIyIiB5Mj0iNCIgLz4KICA8bGluZSB4MT0iMTAiIHgyPSIxMCIgeTE9IjIiIHkyPSI0IiAvPgogIDxsaW5lIHgxPSIxNCIgeDI9IjE0IiB5MT0iMiIgeTI9IjQiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/coffee
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Coffee: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M17 8h1a4 4 0 1 1 0 8h-1' }],
    ['path', { d: 'M3 8h14v9a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4Z' }],
    ['line', { x1: '6', x2: '6', y1: '2', y2: '4' }],
    ['line', { x1: '10', x2: '10', y1: '2', y2: '4' }],
    ['line', { x1: '14', x2: '14', y1: '2', y2: '4' }],
  ],
];

export default Coffee;
