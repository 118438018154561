import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name form-input
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMTIiIHg9IjIiIHk9IjYiIHJ4PSIyIiAvPgogIDxwYXRoIGQ9Ik0xMiAxMmguMDEiIC8+CiAgPHBhdGggZD0iTTE3IDEyaC4wMSIgLz4KICA8cGF0aCBkPSJNNyAxMmguMDEiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/form-input
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const FormInput: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { width: '20', height: '12', x: '2', y: '6', rx: '2' }],
    ['path', { d: 'M12 12h.01' }],
    ['path', { d: 'M17 12h.01' }],
    ['path', { d: 'M7 12h.01' }],
  ],
];

export default FormInput;
